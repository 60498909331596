import { useTranslations } from 'next-intl';
import { Button } from '@/components/ui/button/Button';
import { LoadingButton } from '@/components/ui/button/LoadingButton';
import { useAuthenticationContext } from '@/providers/auth-provider';

export const GuestNavigation = () => {
  const t = useTranslations();
  const { login, loginDisabled } = useAuthenticationContext();

  return (
    <div className='flex h-bottom-nav items-center justify-between gap-2 bg-kl-dark px-5 text-white'>
      {loginDisabled ? (
        <LoadingButton
          fullWidth
          dark
          zIndex={10}
          className='bg-kl-gradient relative w-full after:absolute after:inset-0 after:rounded-full after:bg-black after:bg-opacity-50'
        />
      ) : (
        <Button
          label={t('common-sign_up-login')}
          color='gradient'
          fullWidth
          onClick={login}
          disabled={loginDisabled}
        />
      )}
    </div>
  );
};
