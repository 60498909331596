import clsx from 'clsx';
import { memo } from 'react';
import { Link } from 'react-transition-progress/next';
import { NotificationsParts } from './parts/Notifications';
import { Avatar } from '@/components/ui/avatar/Avatar';
import { IconEye } from '@/components/ui/icons/IconEye';
import { IconHearts2 } from '@/components/ui/icons/IconHearts2';
import { IconList } from '@/components/ui/icons/IconList';
import { PATHS } from '@/constants/path';
import { useAuthenticatedNavigation } from '@/hooks/use-authenticated-navigation';
import type { NavigationItem } from '@/hooks/use-authenticated-navigation';
import { usePathname } from '@/i18n/routing';

export const AuthenticatedNavigation = () => {
  const { spNavigationItems: items, unread, avatar } = useAuthenticatedNavigation();
  const MemoizedIconList = memo(IconList);
  const MemoizedIconHearts2 = memo(IconHearts2);
  const MemoizedIconEye = memo(IconEye);

  const pathname = usePathname();

  const isActive = (item: NavigationItem) => {
    return pathname === item.path || item.additionalPaths?.includes(pathname);
  };

  // ナビゲーションに含まれないページで全てのアイコンをアクティブ状態にする
  const isAllActive = () => {
    return !items.some(
      (item) => pathname === item.path || item.additionalPaths?.includes(pathname),
    );
  };

  const getIconActiveClass = (item: NavigationItem) => {
    const active = isAllActive() || isActive(item);
    return clsx('size-full', { 'opacity-50': !active });
  };

  const getIconComponent = (item: NavigationItem) => {
    const commonProps = {
      width: '30',
      height: '30',
      className: getIconActiveClass(item),
    };
    const commonIconWrapperStyle = 'relative size-[3rem]';

    switch (item.path) {
      case PATHS.CHANNELS:
        return (
          <div className={commonIconWrapperStyle}>
            <MemoizedIconList {...commonProps} />
          </div>
        );
      case PATHS.FOLLOWING:
        return (
          <div className={commonIconWrapperStyle}>
            <MemoizedIconHearts2 {...commonProps} />
          </div>
        );
      case PATHS.ROOT:
        return (
          <div className={commonIconWrapperStyle}>
            <MemoizedIconEye {...commonProps} />
          </div>
        );
      case PATHS.NOTIFICATIONS_ME || PATHS.NOTIFICATIONS_NEWS || PATHS.NOTIFICATIONS_NEWS_DETAIL:
        return (
          <NotificationsParts
            {...commonProps}
            active={(isAllActive() || isActive(item)) ?? false}
            unread={unread}
          />
        );
      case PATHS.SETTINGS:
        return <Avatar size='sm' src={avatar} />;
      default:
        return null;
    }
  };

  return (
    <div className='flex h-bottom-nav items-center justify-between rounded-tl-[3rem] rounded-tr-[3rem] bg-kl-dark px-3 text-white'>
      {items.map((item) => (
        <Link
          key={item.path}
          href={item.path}
          className='mt-[0.4rem] flex flex-col items-center gap-[0.6rem]'
          aria-current={isActive(item) ? 'page' : undefined}
        >
          {getIconComponent(item)}
          <div className={`text-button-10 ${getIconActiveClass(item)}`}>{item.title}</div>
        </Link>
      ))}
    </div>
  );
};
