import { useQuery } from '@apollo/client';
import { useTranslations } from 'next-intl';
import { GetMeIsUnreadMyNotificationDocument } from '@/__generated__/graphql';
import { IMAGE_PATH } from '@/constants/image-path';
import { PATHS } from '@/constants/path';
import type { PathValues } from '@/constants/path';

/**
 * ログイン後ナビで利用する共通処理
 */

type AuthenticatedNavigationReturn = {
  spNavigationItems: NavigationItem[];
  pcNavigationItems: NavigationItem[];
  unread: boolean;
  avatar: string;
};

export interface NavigationItem {
  title: string;
  path: PathValues;
  additionalPaths?: string[];
}

export const useAuthenticatedNavigation = (): AuthenticatedNavigationReturn => {
  const { data, error } = useQuery(GetMeIsUnreadMyNotificationDocument);
  const t = useTranslations();

  const items = {
    CHANNELS: {
      title: t('common_channels'),
      path: PATHS.CHANNELS,
    },
    FOLLOWING: {
      title: t('common_following'),
      path: PATHS.FOLLOWING,
    },
    DISCOVER: {
      title: t('common_discover'),
      path: PATHS.ROOT,
    },
    NOTIFICATIONS: {
      title: t('common_notifications'),
      path: PATHS.NOTIFICATIONS_ME,
      additionalPaths: [PATHS.NOTIFICATIONS_NEWS, PATHS.NOTIFICATIONS_NEWS_DETAIL],
    },
    SETTINGS: {
      title: t('common_settings'),
      path: PATHS.SETTINGS,
    },
  };

  const spNavigationItems: NavigationItem[] = [
    items.CHANNELS,
    items.FOLLOWING,
    items.DISCOVER,
    items.NOTIFICATIONS,
    items.SETTINGS,
  ];

  const pcNavigationItems: NavigationItem[] = [
    items.DISCOVER,
    items.CHANNELS,
    items.FOLLOWING,
    items.NOTIFICATIONS,
    items.SETTINGS,
  ];

  if (error) {
    //TODO: エラー処理
    console.error(error);
  }

  const unread = !!data?.me?.isUnreadMyNotification;

  const avatar = data?.me?.iconImage?.url || IMAGE_PATH.DEFAULT_USER;

  return {
    spNavigationItems,
    pcNavigationItems,
    unread,
    avatar,
  };
};
