'use client';

import { IconBell } from '@/components/ui/icons/IconBell';

export const NotificationsParts = ({
  width,
  height,
  unread,
  active,
}: {
  width: string;
  height: string;
  unread: boolean;
  active: boolean;
}) => {
  return (
    <div className='relative size-[3rem]'>
      {unread && (
        <span className='absolute right-0 top-0 z-10 block size-2.5 rounded-full bg-[#f00]' />
      )}
      <IconBell width={width} height={height} className={active ? '' : 'opacity-50'} />
    </div>
  );
};
