import { useLocale, useTranslations } from 'next-intl';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-transition-progress/next';
import { IconLogo, IconClose, IconHamburger, IconX } from '@/components/ui/icons';
import { PATHS } from '@/constants/path';
import { usePathname } from '@/i18n/routing';

export const MobileGuestNavigation = () => {
  const t = useTranslations();
  const locale = useLocale();
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();

  const toggleMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  // TODO: no-scroll関連を一時的にコメントアウト
  // useEffect(() => {
  //   if (isOpen) {
  //     document.body.classList.add('no-scroll');
  //   } else {
  //     document.body.classList.remove('no-scroll');
  //   }
  //   return () => {
  //     document.body.classList.remove('no-scroll');
  //   };
  // }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  const handleLinkClick = () => {
    // NOTE: 現在のページがrootだった場合、pathnameが変わらずuseEffectの処理が動かないため、rootだったらモーダルを閉じる処理を入れている
    if (pathname !== PATHS.ROOT) return;
    setIsOpen(false);
  };

  const aboutKlew = () => {
    if (locale === 'en') {
      return (
        <>
          {t('settings-menu-about_en')}
          <IconLogo width={90} height={16.952} color='white' className='w-[9rem] shrink-0' />
        </>
      );
    }

    return (
      <>
        <IconLogo width={90} height={16.952} color='white' className='w-[9rem] shrink-0' />
        {t('settings-menu-about_jp')}
      </>
    );
  };

  return (
    <div className='pointer-events-none fixed bottom-[var(--bottom-nav-height)] left-1/2 z-20 h-sp-guest-menu w-full -translate-x-1/2 bg-opacity-[0.8] md:w-md-base lg:hidden'>
      {isOpen && (
        <div className='pointer-events-auto flex h-full flex-col'>
          <Link
            href={PATHS.ROOT}
            className='flex h-header items-center justify-center bg-kl-dark'
            onClick={handleLinkClick}
          >
            <IconLogo width={105} height={20} color='white' className='mx-auto' />
          </Link>
          <div className='flex-1 overflow-auto bg-kl-black pb-[5rem]'>
            <div className='flex flex-col overflow-auto border-t border-[#D6D6D6] bg-kl-black'>
              <div className='flex flex-col gap-[3rem]'>
                <MenuRows>
                  <MenuRow href={PATHS.ABOUT}>
                    <div className='flex items-center gap-[0.8rem]'>{aboutKlew()}</div>
                  </MenuRow>
                  <MenuRow href={PATHS.ABOUT_FOR_ARTISTS} externalLink>
                    {t('settings-menu-about_for_artists')}
                  </MenuRow>
                  <MenuRow href={PATHS.CONTACT} externalLink>
                    {t('settings-menu-contact')}
                  </MenuRow>
                  <MenuRow href={PATHS.FAQ} externalLink>
                    {t('settings-menu-faq')}
                  </MenuRow>
                  <MenuRow href={PATHS.TERMS} externalLink>
                    {t('settings-menu-terms')}
                  </MenuRow>
                  <MenuRow href={PATHS.PRIVACY_POLICY} externalLink>
                    {t('common_privacy-policy')}
                  </MenuRow>
                  <MenuRow href={PATHS.LEGAL} externalLink>
                    {t('settings-menu-law')}
                  </MenuRow>
                </MenuRows>
                <MenuRows>
                  <MenuRow
                    href={PATHS.X}
                    externalLink
                    icon={<IconX width={16} height={16} fillColor='white' />}
                  >
                    {t('settings-menu-x')}
                  </MenuRow>
                </MenuRows>
                <div className='px-[2rem] pb-[3rem] pt-5 text-[1rem] text-white'>
                  Copyright © 2024 KLEW All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <button
        type='button'
        className='pointer-events-auto absolute bottom-[1.5rem] left-[2rem] flex size-[4rem] items-center justify-center rounded-full bg-kl-dark'
        onClick={toggleMenuOpen}
      >
        {isOpen ? (
          <IconClose color='white' height='18' width='18' />
        ) : (
          <IconHamburger color='white' height='18' width='18' />
        )}
      </button>
    </div>
  );
};

const MenuRows = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex flex-col border-0 border-b border-solid border-[#D6D6D6] text-body-14 text-white'>
      {children}
    </div>
  );
};

const MenuRow = ({
  children,
  href,
  externalLink = false,
  icon,
}: {
  children: React.ReactNode;
  href: string;
  externalLink?: boolean;
  icon?: ReactNode;
}) => {
  return (
    <Link
      href={href}
      target={externalLink ? '_blank' : '_self'}
      className='flex h-[6.4rem] items-center border-0 border-t border-solid border-[#D6D6D6] px-[2.5rem] text-[1.4rem] text-white'
    >
      {icon && <span className='mr-[0.9rem]'>{icon}</span>}
      {children}
    </Link>
  );
};
