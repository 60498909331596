'use client';

import { BottomNavigation } from '@/components/layout/bottom-navigation';
import { MobileGuestNavigation } from '@/components/layout/mobile-guest-navigation';
import { useAuthenticationContext } from '@/providers/auth-provider';

export default function WithBottomNav({ children }: { children: React.ReactNode }) {
  const { isRegistered } = useAuthenticationContext();
  return (
    <>
      {children}
      {!isRegistered && <MobileGuestNavigation />}
      <div className='fixed bottom-0 left-1/2 z-10 w-full max-w-sm-full -translate-x-1/2 md:max-w-md-base lg:hidden'>
        <BottomNavigation loggedin={isRegistered} />
      </div>
    </>
  );
}
