import clsx from 'clsx';
import Image from 'next/image';

const SIZES = {
  xs: 'size-5',
  sm: 'size-[3rem]',
  md: 'size-12',
  lg: 'size-[10.4rem]',
  xl: 'size-[15rem]',
} as const;
type SizeType = keyof typeof SIZES;

export type IconProps = {
  src: string;
  size?: SizeType;
  alt?: string;
  className?: string;
};

export const Avatar = ({ src, size = 'md', alt = '', className }: IconProps) => {
  return (
    <div
      className={clsx(`relative ${SIZES[size]}`, {
        [`${className}`]: className,
      })}
    >
      <Image src={src} className='block rounded-full' fill sizes='auto' alt={alt} />
    </div>
  );
};
