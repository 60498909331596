'use client';

import clsx from 'clsx';
import { Loading } from '../loading/Loading';

export type Props = {
  fullWidth?: boolean;
  className?: string;
  dark?: boolean;
  zIndex?: number;
};

export const LoadingButton = ({ fullWidth = false, className, dark = false, zIndex }: Props) => {
  const disabledStyle = 'bg-black bg-opacity-10 text-white';
  const baseStyle = 'rounded-full px-5 py-2 min-w-[16.4rem] h-12 flex items-center justify-center';
  const style = clsx(baseStyle, disabledStyle);

  return (
    <button type='button' className={clsx(className, style, { 'w-full': fullWidth })} disabled>
      <Loading
        fullscreen={false}
        dark={dark}
        transparentBg
        zIndex={zIndex}
        className='!scale-[0.375] bg-opacity-0'
      />
    </button>
  );
};
